<template>
  <div class="container anInManage" v-title :data-title="$t('i18n.anInManage')">
    <div id="outer-title">{{ $t("i18n.anInManage") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
// import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "AnnualInannualInspectionView",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    // const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      yearCheckStatus: [],
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          type: "input",
          rows: false,
          search: true,
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
          width: 350,
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          search: true,
          align: "left",
          width: 350,
        },
        {
          prop: "annualValidity",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "yearCheckStatus",
          label: "aiStatus",
          rows: false,
          search: true,
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
          uploadUrl: "yearCheckStatus",
        },
        {
          prop: "yearCheckStatusText",
          label: "aiStatus",
          align: "center",
          formatter: (row) => {
            let color = state.yearCheckStatus.filter((item) => {
              return item.value == row.yearCheckStatus;
            })[0].description;
            return `<span style="color: ${color}">
              ${row.yearCheckStatusText}
            </span>`;
          },
        },
        {
          prop: "nextAnnualDate",
          label: "nextAnnualInspectionDate",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "details",
            icon: "el-icon-notebook-2",
            show: proxy.$defined.btnPermission("年检管理详情"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/maintenance/annualInfocou",
                query: { id: row.id },
              });
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.maintenance.getAnnualData(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const getYearCheckStatus = () => {
      proxy.$api.common.getLineageByCode("yearCheckStatus").then((res) => {
        state.yearCheckStatus = res.data.lineages;
      });
    };
    getYearCheckStatus();

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss">
.anInManage.container {
  .el-form--inline {
    .el-input {
      line-height: unset;
    }
  }
}
</style>
